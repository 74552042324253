<template>
  <div class="layout">
    <!-- <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      success-text="刷新成功"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        :offset="30"
      > -->
    <div class="list" v-for="(item, index) in storeList" :key="index">
      <p class="time">{{ item.updateTime }}</p>
      <div class="content" @click="toDetail(item)">
        <div class="left">
          <h2 class="title">{{ item.newsTitle }}</h2>
          <div class="str" v-html="item.sketch"></div>
        </div>
        <div class="right">
          <img src="../../assets/images/more_icon.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <p class="pull_up" v-if="!(error || finished || loading)">
          上拉 查看更多推荐商品 <svg-icon icon-class="home_more_icon" />
        </p> -->
    <!-- </van-list>
    </van-pull-refresh> -->
  </div>
</template>

<script>
export default {
  name: "mine",

  data() {
    return {
      storeList: [],
      isLoading: false,
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      total: null,
    };
  },

  mounted() {},
  created() {
    this.getStore();
  },

  methods: {
    async getStore() {
      await this.$API.platform
        .News({
          object: {
            type: "XWZX",
          },
          pageNum: this.pageNum,
          pageSize: 10,
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") return false;
          this.total = res.data.data.total;
          let data = res.data.data;
          this.storeList = this.storeList.concat(data);
          this.pageNum++;
          this.loading = false;
          if (this.pageNum > this.total / 10) {
            this.finished = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.loading = false;
        });
    },
    async onLoad() {
      this.getStore();
    },
    onRefresh() {
      setTimeout(() => {
        // Toast("刷新成功");
        this.storeList = [];
        this.pageNum = 1;
        this.finished = false;
        this.isLoading = false;
        this.getStore();
      }, 1000);
    },
    toDetail(data) {
      this.$router.push({
        path: "news/newsDetail",
        query: {
          newsCode: data.newsCode,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  position: absolute;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  //   max-width: 380px;
  // margin: 0 auto;
  overflow-y: scroll;
  // height: 100vh;
  background: #f5f5f5;
}
.list {
  margin: 20px 12px 0 12px;
  text-align: center;
}
.list:last-child {
  margin-bottom: 12px;
}
.content {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 14px;
}
.time {
  margin-bottom: 12px;
  color: #666;
}
.left {
  width: 85%;
  h2,
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  h2 {
    margin-bottom: 12px;
    font-size: 16px;
    color: #333;
  }
}
.str {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #666;
}
.right {
  img {
    width: 6px;
    height: 11px;
  }
}
</style>